import { useBrandsInfoStore } from '~/store/brandsInfo';

export default defineNuxtRouteMiddleware((to, from) => {
    

    const runtimeConfig = useRuntimeConfig();

    if(runtimeConfig.public.baseUrl == 'https://www.marcosautomocion.es/'){

        const {isActiveBrand} = useBrandsInfoStore();
    
        if(to.params?.marca){ //Es de marcas

            if(!isActiveBrand(to.params.marca)){
                throw createError({ statusCode: 404, message: 'La página no existe' });      
            }
        }
    }


});
  